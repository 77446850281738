import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, videoNullCheck, imageNullCheck } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid/blog-post-preview-grid'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid/project-preview-grid'
import AboutPreview from '../components/about-preview/about-preview.'
import VideoBanner from '../components/video-banner/video-banner'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import ContactUsForm from '../components/contact-us-form/contact-us-form'
import SocialMediaEmbedFeed from '../components/social-media-embed-feed/social-media-embed-feed';
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import '../assets/scss/blk-design-system-react.scss'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    index: sanityPage(_id: { regex: "/(drafts.|)index/" }) {
				id
        title
				_rawExcerpt
        _rawBody
				_rawSubtitle
        useVideo
				socialEmbedFeed {
          url
        }
        pageBranding {
          asset {
            _id
          }
        }
        mainVideo {
          embed {
            _type
            asset {
              url
            }
          }
        }
        mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      }

    contact: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
          id
        title
        _rawBody
				mainImage {
					asset {
              _id
            }
					crop {
						_key
						_type
						top
						bottom
						left
						right
					}
					hotspot {
						_key
						_type
						x
						y
						height
						width
					}
				}
        pageBranding {
          asset {
            _id
          }
        }
      }
		
		about: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
				id
				title
				_rawExcerpt
				mainImage {
					asset {
              _id
            }
					crop {
						_key
						_type
						top
						bottom
						left
						right
					}
					hotspot {
						_key
						_type
						x
						y
						height
						width
					}
				}
				secondaryImage {
					asset {
              _id
            }
					crop {
						_key
						_type
						top
						bottom
						left
						right
					}
					hotspot {
						_key
						_type
						x
						y
						height
						width
					}
				}
      }

    projects: allSanityProject(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawCategories(resolveReferences: {maxDepth: 1})
          _rawExcerpt
          slug {
            current
          }
        }
      }
		}

		companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
			name
			phoneNumber
			email
			miscInfo
      address1
      address2
      zipCode
			city
			state
			country
			socialMedia
    }

    posts: allSanityPost(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          authors {
            _key
            _type
            person {
              id
              image {
                asset {
                  _id
                }
              }
              name
            }
          }
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          _rawCategories(resolveReferences: {maxDepth: 1})
          slug {
            current
          }
        }
      }
    }
  }
`
const IndexPage = props => {
	const { data, errors } = props;
  const styleSheets = [
    "#embedBottomBarContainer { background: rgba(0, 0, 0, 0) !important; border: none !important; } .sharedForm { background: #171941 !important; } .sharedForm .formFieldAndSubmitContainer { background: #171941 !important; } .sharedFormField { background: #171941 !important; } .sharedForm .formHeader { background: #171941 !important; font-family: inherit !important; font-weight: 400 !important; color: #32325d !important; } .sharedFormField .title { /* font-family: Poppins, sans-serif; */ font-size: .875rem !important; font-weight: 400 !important; line-height: 1.5 !important; color: #525f7f !important; text-align: left !important; } .sharedFormField .cellContainer .formCell .baymax { padding: 10px 18px !important; border-color: #2b3553 !important; border-radius: .4285rem !important; font-size: .75rem !important; } .embedded .sharedForm { overflow: hidden !important; }"
  ];
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (typeof data === "object" && Object.keys(data).length > 0 ? data.site : {}); 
	const index = (typeof data === "object" && Object.keys(data).length > 0 ? data.index : {}); 
  const contact = (typeof data === "object" && Object.keys(data).length > 0 ? data.contact : {}); 
  const companyInfo = (typeof data === "object" && Object.keys(data).length > 0 ? data.companyInfo : {}); 
  const posts = (typeof data === "object" && Object.keys(data).length > 0 ? data.posts : {}); 
  const about = (typeof data === "object" && Object.keys(data).length > 0 ? data.about : {}); 
  const projects = (typeof data === "object" && Object.keys(data).length > 0 ? data.projects : {}); 
  const postNodes = Object.keys(posts).length > 0 ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs) : [];
  const projectNodes = Object.keys(projects).length > 0 ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs) : [];
	if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  };

  return (
		<Layout 
			transparent={(imageNullCheck(index) || videoNullCheck(index)) ? true : false}
		>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
			<VideoBanner 
				{ ...index} 
				position={'bottom-left'}
			/>
      <div>
        <h1 hidden>Welcome to {site.title}</h1>
				<AboutPreview {...about} companyInfo={companyInfo}/>
        <Row>
          {projectNodes && (
            <ProjectPreviewGrid
              title='Latest Projects'
							supertitle='Our Work'
              nodes={projectNodes}
              browseMoreHref='/projects/'
            />
          )}
        </Row>
        <Row>
          {postNodes && (
            <BlogPostPreviewGrid
							title='Latest Posts'
							supertitle='Our Story'
              nodes={postNodes}
              browseMoreHref='/blog/'
            />
          )}
        </Row>
				<Container fluid>
					{
						index.socialEmbedFeed && index.socialEmbedFeed.length && (
							<SocialMediaEmbedFeed supertitle={'Keep Up With Us'} title={'On Social Media'} posts={index.socialEmbedFeed}/>
						)
					}
				</Container>
				<Row>
					{
						contact && (
							<ContactUsForm page={contact} companyInfo={companyInfo}/>
						)
					}
				</Row>
      </div>
    </Layout>
  );
}

export default IndexPage
