import React, { Component, Fragment } from 'react'
import { getInstagramEmbed } from '../../lib/helpers'
import InstagramEmbed from 'react-instagram-embed'
import MdCloseCircle from 'react-ionicons/lib/MdCloseCircle'


import {
  Card,
  CardBody,
	Modal,
	ModalHeader,
	ModalBody,
  Row,
  Col
} from "reactstrap";

class SocialMediaEmbedFeed extends Component {
	constructor(props) {
		super(props)
		this.state = {
			 postData: [],
			 isModalOpen: false,
			 modalData: null
		}
	}
	
	componentWillMount(){
		if( this.props.posts && this.props.posts.length ) {
			this.props.posts.map((post) => (
				getInstagramEmbed(post.url)
					.then(res =>{
						this.setState({
							postData: [ ...res, ...this.state.postData]
						});
					})
					.catch(err => {
						throw new Error(err);
					})
			))
		}	
	}

	toggleModal = (url) => {
    this.setState(prevState => ({
			isModalOpen: !prevState.isModalOpen,
			modalData: url
    }));
  }

	render() {
		const posts = this.state.postData;
		const { title, supertitle } = this.props;
		return (
			<Fragment>
				<Row style={{paddingTop: 60}}>
					{title && (
						<div className={'ml-auto mr-auto text-center'}>
							<h6 className={'supertitle'}>{supertitle}</h6>
							<h2 className={'title'} style={{marginBottom: 60}}>{title}</h2>
						</div>
					)}
				</Row>
				<Row>
					{
						posts && (
							posts.map((post, index) => {
								const { link, preview, title } = post;
								const newTitle = title.split('\n').map((item, index) => {
									if (index === 0) {
										return <h5 style={{fontWeight: 'bold'}} key={Math.random(10)}>{item}</h5>;
									} else {
										return <p key={Math.random(10)}>{item}</p>;
									}
								});
								return(
									<Col lg={3} md={4} sm={6} key={Math.random(10)}>
										<div onClick={()=> this.toggleModal(link)}>
											<Card
												className="card-background"
												style={{
													backgroundImage:`url(${preview})`,
													maxHeight: 400
												}}
											>
												<CardBody style={{
													display: 'flex',
													flexFlow: 'column',
													justifyContent: 'center',
													alignContent: 'center',
													textAlign: 'left',
													padding: 20
												}}>
													{ (newTitle) }
												</CardBody>
											</Card>
										</div>
									</Col>
								)
								}
							)
						)
					}
					<Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} className={this.props.className}>
						<div onClick={()=> this.toggleModal()}>
							<ModalHeader toggle={this.toggleModal} close={<MdCloseCircle />}></ModalHeader>
						</div>
						<ModalBody
							style={{
								display: 'flex',
								justifyContent: 'center'
							}}>
							{
								this.state.isModalOpen && (
									<InstagramEmbed
										url={this.state.modalData}
										maxWidth={400}
										hideCaption={false}
										containerTagName='div'
										protocol=''
										injectScript
										onLoading={() => {}}
										onSuccess={() => {}}
										onAfterRender={() => {}}
										onFailure={() => {}}
									/>
								)
							}
						</ModalBody>
					</Modal>
			</Row>
			</Fragment>
		)
	}
}

export default SocialMediaEmbedFeed;