import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockContent from '../block-content/'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function AboutPreview (props) {
  const { _rawExcerpt, title, companyInfo, mainImage, secondaryImage } = props
	return (
		<div 
			className="section-image"
			style={{
				background: secondaryImage && secondaryImage.asset ? 
					`url(${(/https\:\/\/|http\:\/\//g).test(secondaryImage.asset) ? secondaryImage.asset : imageUrlFor(buildImageObj(secondaryImage)).width(600).height(600).fit('crop').url()})` :
					'#5e4ca5'
				}}
		>
			<Container className={'info-banner'}>
				<Row className={'justify-content-center'}>
					<Col sm="12" lg="6">
					<Row className="featured-image justify-content-center">
						<Col md="8" lg="12">
							<div
								style={{
									background: `url(
										${(/https\:\/\/|http\:\/\//g).test(mainImage.asset) ? mainImage.asset : imageUrlFor(buildImageObj(mainImage)).height(600).fit('crop').url()}
									)`,
									height: 600,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat'
								}}
							/>
						</Col>
					</Row>
					</Col>
					<Col style={{marginTop: 50}} sm="12" lg="6">
						<Row className={'justify-content-center'}>
								<Col className={'col-10'} md={10} lg={12}>
										<h4>About Us</h4>
										<h1 className="title">We are {companyInfo.name}</h1>
										<div style={{display: 'flex', flexFlow: 'column nowrap'}}>
											<div className='info preview-image' style={{margin: 0, paddingTop: 10}}>
												<p className="description no-padding">
													<BlockContent blocks={_rawExcerpt || []} />
												</p>
											</div>
											<Button
												className="btn-neutral btn-simple"
												color="primary"
												href="/about/"
												style={{alignSelf: 'flex-start'}}
											>
												Learn More <IosArrowForward/>
											</Button>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
			</Container>
		</div>
  )
}

export default AboutPreview