import React, { Fragment } from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'

function Icon (props) {
  return (
    <div className={'icon--container'} style={{ display: 'flex', justifyContent: 'center', margin: '2rem'}}>
      {props.asset && (
        <img
          src={imageUrlFor(buildImageObj(props))
            .width(40)
						.url()}
						
          alt={props.alt}
        />
      )}
    </div>
  )
}

export default Icon
