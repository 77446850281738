import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Figure from './figure'
import Icon from './icon'
import Slideshow from './slideshow'

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2>{props.children}</h2>

        case 'h3':
          return <h3>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'blockquote':
          return <><br/><blockquote>“{props.children}”</blockquote></>

        case 'cite':
          return <><cite className="cite"><small className="small">{props.children}</small></cite><br /><br /></>

        default:
          return <p>{props.children}</p>
      }
    },
    icon (props) {
      return <Icon {...props.node} />
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    }
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
