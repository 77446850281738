import React from 'react'
import PropTypes from 'prop-types'

function buildFileUrl(source) {
  const regex = new RegExp(/\.mp4|\.ogg|\.webm/g);
  const obj = {
    asset: { url: source.asset.url }
  }
  obj.asset.type = (regex).test(obj.asset.url) ? (obj.asset.url).match(regex)[0].replace('.', "") : false;

  if (!obj.asset.type) return;
  return obj
}

function VideoEmbed (props) {
  const { embed } = props[0]
  const file = buildFileUrl(embed);
  return (
    <>
      { file && (
        <video playsInline muted loop autoPlay>
          <source src={file.asset.url} type={`video/${file.asset.type}`} />
        </video>
      )}
    </>
  )
}

export default VideoEmbed;

