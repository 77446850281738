import { Link } from 'gatsby'
import React from 'react'
import { BlogPostPreview } from '../blog-post-preview/blog-post-preview'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import MdInfinite from 'react-ionicons/lib/MdInfinite'
import { chunkArray } from '../../lib/helpers'

// reactstrap components
import {
  Button,
  Container,
  Row,
	Col,
	Card,
  CardBody
} from "reactstrap";

const arrangeGrid = (index, array) => { 
  let result = array.length === 2 ? 6 : 12;
  return result;
}

const BlogPostPreviewGrid = (props) => {
	const chunkLength = props.nodes.length % 2 === 0 ? props.nodes.length / 2 : (props.nodes.length + 1) / 2;
	const postRows = chunkLength > 1 ? chunkArray(props.nodes, 2) : props.nodes;
  return (
		<Col lg={12} style={{paddingTop: 60}}>
			{props.title && (
				<div className={'ml-auto mr-auto text-center'}>
					<h6 className={'supertitle'}>{props.supertitle}</h6>
					<h2 className={'title'} style={{marginBottom: 60}}>{props.title}</h2>
				</div>
			)}
			<Row className={'no-gutters'}>
				{postRows.length &&
					postRows.map((rows, rowIndex, rowArray) => {
						const rowsReassigned = [ ...rows];
						return rowsReassigned.map((row, index, array) => (
							<Col sm={12} xl={ arrangeGrid(index, array)} key={row.id}>
								{
									<BlogPostPreview 
										index={index}
										flipped={(rowIndex + 1) % 2 === 0 && array.length === 2} 
										{...row} 
									/>
								}
							</Col>
						))
					})
				}
			</Row>
			<Row>
				<Col md={"12"} style={{display: "flex", justifyContent: "flex-end"}}>
					<Card className="card-cta no-margin no-radius">
						<CardBody>
							<div className="content-center">
							<h2>
							 Want to See More Posts?
							</h2>
							{props.browseMoreHref && (
								<Link to={props.browseMoreHref}>
									<Button
									className="btn-simple btn-neutral"
									color="neutral"
									type="button"
									>
										Click Here <IosArrowForward/>
									</Button>
								</Link>
							)}
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Col>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
