import { Link } from 'gatsby'
import React from 'react'
import ProjectPreview from '../project-preview/project-preview'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import MdLaptop from 'react-ionicons/lib/MdLaptop'

// reactstrap components
import {
  Button,
  Container,
  Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Badge,
} from "reactstrap";

function ProjectPreviewGrid (props) {
  return (
		<Col lg={12} style={{paddingTop: 60}}>
			<Row>
				{props.title && (
					<div className={'ml-auto mr-auto text-center'}>
						<h6 className={'supertitle'}>{props.supertitle}</h6>
						<h2 className={'title'} style={{marginBottom: 60}}>{props.title}</h2>
					</div>
				)}
			</Row>
			<Row className={'no-gutters'}>
				{props.nodes &&
					props.nodes.map((node, index, array) => (
						<Col lg={Math.floor( 12 / array.length )} key={node.id}>
							<ProjectPreview {...node} />
						</Col>
					))}
			</Row>
			<Row>
				<Col md={"12"} style={{display: "flex", justifyContent: "flex-end"}}>
					<Card className="card-cta no-margin no-radius">
						<CardBody>
							<div className="content-center">
							<h2>
							 Want to Read About More Projects?
							</h2>
							{props.browseMoreHref && (
								<Link to={props.browseMoreHref}>
									<Button
									className="btn-simple btn-neutral"
									color="neutral"
									type="button"
									>
										Click Here <IosArrowForward/>
									</Button>
								</Link>
							)}
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Col>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid
