import { Link } from 'gatsby'
import React from 'react'
import { cn, buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockText from '../block-text'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

// reactstrap components
import {
  Button,
	Card,
	Badge,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const tagName = [
  'warning',
  'info',
  'primary',
  'danger',
  'success'
];

if (!Array.prototype.getRandomIndex) {
  Array.prototype.getRandomIndex = function() {
    return this[Math.floor(Math.random() * this.length)];
  }
}


const PreviewCardTitleCTA = (props) => (
	<Card 
		className={`card-blog card-plain`}
	>
		<CardHeader style={{paddingLeft: 0}}>
			<h2 className="title">{props.title}</h2>
			{ 
				props._rawCategories && ( props._rawCategories.map( category => (
					<Badge key={Math.random(10)} color={tagName.getRandomIndex()} className="mr-1">
						{category.title}
					</Badge>
				)) )
				} 
		</CardHeader>
		<div className="card-image">
			<img
				alt="..."
				className="img rounded img-raised"
				src={`${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()}`}
			/>
		</div>
		<CardFooter style={{
				display: 'flex',
				flexFlow: 'row',
				justifyContent: 'flex-end',
		}}>
			<div className="author">
				<Button
					className="btn-round btn-simple"
					color="primary"
				>
					<IosArrowForward/>
					Read More
				</Button>
			</div>
		</CardFooter>
	</Card>
);

const PreviewBackgroundImage = (props) => (
	<Card className={`card card-background partial ${props.isTitle ? "title-card" : ""}`} 
			style={{ 
				backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()})`,
				height:'100%'
			}}>
			<CardBody className={"content-bottom"}>
					<CardHeader>
						{
							props.icon && (
								<props.icon fontSize={"45px"}/>
							)
						}
						{ 
							props._rawCategories && ( props._rawCategories.map( category => (
								<div 
									key={Math.random(10)}
									className={'card-category'}
									style={{
										textTransform: 'uppercase',
										fontWeight: '400',
										fontSize: '.75rem',
										lineHeight: '1.2rem',
										marginBottom: '.1rem',
										marginTop: '.1rem',
										color: "#ffffff"
									}}>
									{category.title}
								</div>
							)) )
						} 
					</CardHeader>
					<CardText>
						<span className={"card-title"}>{props.title}</span>
					</CardText>
			</CardBody>
		</Card>
);


const ProjectPreview = (props) => {
	return(
		<Link 
			to={ props.slug && props.slug.current ? `/project/${props.slug.current}` : props.link}
		>
			<Card
				className="card-blog card-background no-border-radius no-margin"
				data-animation="zooming"
			>
				<div
					className="full-background"
					style={{
						backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()})`,
						height:'100%'
					}}
				/>
				<CardBody>
					<div className="content-bottom">
						{ 
							props._rawCategories && ( props._rawCategories.map( category => (
								<Badge key={Math.random(10)} color={tagName.getRandomIndex()} className="mr-1">
									{category.title}
								</Badge>
							)) )
						} 
						<CardTitle tag="h3">{props.title}</CardTitle>
					</div>
				</CardBody>
			</Card>
		</Link>
	)
}

const ProjectPreviewTwo = (props) => {
  return (
		<Link style={{
					display: 'block',
					color: 'inherit',
					textDecoration: 'none',
					height: '100%'
				}}
				to={ props.slug && props.slug.current ? `/project/${props.slug.current}` : props.link}
			>
      {props.mainImage && props.mainImage.asset && (
				<>
					{
						props.isTitle ? 
						<PreviewBackgroundImage { ...props}/> :	
						<PreviewCardTitleCTA { ...props}/>
					}
				</>
      )}
    </Link>
  )
}

        
// function ProjectPreview (props) {
//   return (
//     <Link className={styles.root} to={`/project/${props.slug.current}`}>
//       <div className={styles.leadMediaThumb}>
//         {props.mainImage && props.mainImage.asset && (
//           <img
//             src={imageUrlFor(buildImageObj(props.mainImage))
//               .width(600)
//               .height(Math.floor((9 / 16) * 600))
//               .url()}
//             alt={props.mainImage.alt}
//           />
//         )}
//       </div>
//       <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
//       {props._rawExcerpt && (
//         <div className={styles.excerpt}>
//           <BlockText blocks={props._rawExcerpt} />
//         </div>
//       )}
//     </Link>
//   )
// }

export default ProjectPreview
