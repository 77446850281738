import { Link } from 'gatsby'
import React from 'react'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoTwitter from 'react-ionicons/lib/LogoTwitter'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline'
import { buildImageObj } from '../../../lib/helpers'
import { imageUrlFor } from '../../../lib/image-url'

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: this.props.transparent ? "navbar-transparent" : 'bg-brand'
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }

  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-brand"
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: this.props.transparent ? "navbar-transparent" : 'bg-brand'
      });
    }
  };

  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };

  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };

  render() {
		const { companyInfo, siteLogo } = this.props;
    const iconColor = "#ffffff"
		const socialMedia = (link) => {
			switch (true) {
				case (/instagram\.com/g).test(link):
					return {
						    link: link,
						    icon:  LogoInstagram,
						    copy: "Instagram",
						    title: "Follow us on Instagram"
						  }
				case (/facebook\.com/g).test(link):
					return {
						    link: link,
								icon:  LogoFacebook,
								copy: "Facebook",
								title: "Follow us on Facebook"
						  }
				case (/twitter\.com/g).test(link):
					return {
						    link: link,
								icon:  LogoTwitter,
								copy: "Twitter",
								title: "Follow us on Twitter"
						  }
				default:
					break;
			}
		}

    const routingLinks = [
      {
        copy: "About",
        path: "/about/"
      },
      {
        copy: "Projects",
        path: "/projects/"
      },
      {
        copy: "Blog",
        path: "/blog/"
      },
      {
        copy: "Contact",
        path: "/contact/"
      },
    ];

    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              rel="noopener noreferrer"
              title={companyInfo && (companyInfo.name)}
							tag={Link}
            >
							<img style={{borderRadius: 0, width: 200}} src={imageUrlFor(buildImageObj(siteLogo)).width(200).url()}/>
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
									<NavLink
										data-placement="bottom"
										href={"/"}
										rel="noopener noreferrer"
										title={companyInfo && (companyInfo.name)}
										className={'no-padding'}
									>
										<img style={{borderRadius: 0}} src={imageUrlFor(buildImageObj(siteLogo)).url()}/>
									</NavLink>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <IosCloseCircleOutline color={iconColor} fontSize={"32px"} />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              {
                companyInfo.socialMedia && companyInfo.socialMedia.length > 0 ?
                companyInfo.socialMedia.map((social) => {
									const {icon, link, copy, title} = socialMedia(social);
                  const Icon = icon;
                  return(
                    <NavItem className="p-0" key={Math.random(10)}>
                      <NavLink
                        data-placement="bottom"
                        href={link}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={title}
                      >
                        <Icon color={iconColor}/>
                        <p className="d-lg-none d-xl-none">{copy}</p>
                      </NavLink>
                    </NavItem>
                  )
                }) : null
              }
              {
                routingLinks && routingLinks.length > 0 ?
                routingLinks.map(({copy, path}) => {
                  return(
                    <NavItem key={Math.random(10)}>
                      <NavLink tag={Link} to={path}>
                        {copy}
                      </NavLink>
                    </NavItem>
                  )
                }) : null
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavBar
