import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, getBlogUrl } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockText from '../block-text'
import { format } from 'date-fns'
import MdPaper from 'react-ionicons/lib/MdPaper'

// reactstrap components
import {
	Button,
	Badge,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const tagName = [
  'warning',
  'info',
  'primary',
  'danger',
  'success'
];

const tagNameTitle = [
  'text-warning',
  'text-info',
  'text-primary',
  'text-danger',
  'text-success'
];

if (!Array.prototype.getRandomIndex) {
  Array.prototype.getRandomIndex = function() {
    return this[Math.floor(Math.random() * this.length)];
  }
}


export const BlogPostPreviewTwo = (props) => {
  const dateSegment = format(props.publishedAt, 'YYYY/MM')
	return(
    <Link to={ props.slug && props.slug.current ? `/blog/${dateSegment}/${props.slug.current}/` : props.link}>
		<div className={`card-container ${props.flipped ? "flipped" : ""}`}>
			<Card className={`card-fashion no-margin ${props.flipped ? "flipped" : ""}`}>
				<CardTitle className={'text-uppercase'}>
					<div style={{display: 'flex', marginTop: 'auto'}}>
						{ 
							props._rawCategories && ( props._rawCategories
								.filter((category, index) => ((index+1) < 5))
								.map( (category) => (
									<Badge key={Math.random(10)} color={tagName.getRandomIndex()} className="mr-1">
										{category.title}
									</Badge>
								)))
						}
					</div>
						{ props.title && ( <h3 style={{marginBottom: 'auto'}}> {props.title} </h3> ) } 
				</CardTitle>
				<CardBody>
						{ props._rawExcerpt && (
							<div className={"info info-horizontal"}>
								<div className={"description"}>
									<BlockText blocks={props._rawExcerpt} classOverride={'card-description'}/>
								</div>
							</div>
						)}
				</CardBody>
			</Card>
			<Card
				className="card-fashion card-background"
				// data-animation="zooming"
			>
				<div
					className={'full-background'} 
					style={{
						backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).width(600).height(600).fit('crop').url()})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat'
					}}
				/>
			</Card>
		</div>
		</Link>
	)
}

export const BlogPostPreviewOne = (props) => {
	const dateSegment = format(props.publishedAt, 'YYYY/MM');
	return(
    <Link to={ props.slug && props.slug.current ? `/blog/${dateSegment}/${props.slug.current}/` : props.link}>
		<Card className="card-blog no-margin card-background no-radius">
			<div
				className="full-background"
				style={{
					backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).width(600).height(600).fit('crop').url()})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat'
				}}
			/>
			<CardBody>
				<div className="content-center">
					<CardTitle tag="h2" style={{marginTop: "auto"}}>{props.title}</CardTitle>
					<div style={{display: 'flex'}}>
						{ 
							props._rawCategories && ( props._rawCategories
								.filter((category, index) => ((index+1) < 5))
								.map( (category) => (
									<Badge key={Math.random(10)} color={tagName.getRandomIndex()} className="mr-1">
										{category.title}
									</Badge>
								)))
						}
					</div>
					<div className="author" style={{marginBottom: "auto"}}>
						{
							props.authors && props.authors[0].person.image &&
							(<img src={imageUrlFor(buildImageObj(props.authors[0].person.image)).url()} alt={props.authors[0].person.name} className="avatar img-raised" />)
						}
					</div>
					{ props._rawExcerpt && (
							<div className={"info info-horizontal"}>
								<div className={"icon icon-primary"}>
									<MdPaper fontSize={'20px'}/>
								</div>
								<div className={"description"}>
									<BlockText blocks={props._rawExcerpt} classOverride={'card-description'}/>
								</div>
							</div>
						)}
				</div>
			</CardBody>
		</Card>
		</Link>
	)
}

export const BlogPostPreview = (props) => {
	switch (true) {
		case props.index % 2 != 0 && !props.flipped:
			return(
				<BlogPostPreviewTwo { ...props}/>
			);
	
		case props.index % 2 == 0 && !props.flipped:
			return(
				<BlogPostPreviewOne { ...props}/>
			);
	
		case props.index % 2 != 0 && props.flipped:
			return(
				<BlogPostPreviewOne { ...props}/>
			);
	
		case props.index % 2 == 0 && props.flipped:
			return(
				<BlogPostPreviewTwo flipped { ...props}/>
			);
	
		default:
			return(
				<BlogPostPreviewOne { ...props}/>
			);
	}
}

const BlogPostPreviewThree = (props) => {
  const dateSegment = format(props.publishedAt, 'YYYY/MM')
  return (
    <Link to={ props.slug && props.slug.current ? `/blog/${dateSegment}/${props.slug.current}/` : props.link}>
      {props.mainImage && props.mainImage.asset && (
        <Card className={`card-plain ${props.isTitle ? "title-card" : ""}`}>
          <div 
            className={'card-img'} 
            style={{
              maxHeight: 380, 
              minHeight: 380,
              backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).width(380).height(380).fit('crop').url()})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          />
          <CardBody className={"content-center"}>
              <CardHeader>
                  {
                    props.icon && (
                      <props.icon fontSize={"45px"}/>
                    )
                  }
                  { 
                    props._rawCategories && ( props._rawCategories.map( category => (
                      <div 
                        key={Math.random(10)}
                        className={`card-category ${tagNameTitle.getRandomIndex()}`}
                        style={{
                          textTransform: 'uppercase',
                          fontWeight: '400',
                          fontSize: '.75rem',
                          lineHeight: '1.2rem',
                          marginBottom: '.1rem',
                          marginTop: '.1rem',
                          color: "#ffffff"
                        }}>
                        {category.title}
                      </div>
                    )) )
                  } 
              </CardHeader>
              <CardText>
                { props._rawExcerpt && (
                  <div>
                    <BlockText blocks={props._rawExcerpt} classOverride={'card-description'}/>
                  </div>
                )}
              </CardText>
              <CardFooter className={"left-align"}>
                {
                  props.authors && (
                    <div className="author">
                      <div className={"left"}>
                        {
                          props.authors[0].person.image &&
                          (<img src={imageUrlFor(buildImageObj(props.authors[0].person.image)).url()} alt={props.authors[0].person.name} className="avatar img-raised" />)
                        }
                        {
                          props.authors[0].person.name && (
                          <span>{props.authors[0].person.name}</span>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </CardFooter>
          </CardBody>
        </Card>
      )}
    </Link>
  )
}

