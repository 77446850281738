import { Link } from 'gatsby'
import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj, cn } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import VideoEmbed from '../video-embed/video-embed'
import BlockContent from '../block-content'


// reactstrap components
import {
  Container,
  Row,
} from "reactstrap";

const excerptAlignment = (position) => {
	switch (position) {
		case 'bottom-left':
			return {
				justifyContent: 'flex-end',
				textAlign: 'left',
				alignContent: 'flex-start'
			}
		case 'top-left':
			return {
				justifyContent: 'flex-start',
				textAlign: 'left',
				alignContent: 'flex-start'
			}
		case 'top-right':
			return {
				justifyContent: 'flex-start',
				textAlign: 'right',
				alignContent: 'flex-start'
			}
		case 'bottom-right':
			return {
				justifyContent: 'flex-end',
				textAlign: 'right',
				alignContent: 'flex-start'
			}
		default:
			return {
				justifyContent: 'flex-start',
				textAlign: 'left',
				alignContent: 'flex-start'
			}
	}
}

const VideoBanner = ({ pageBranding, position, mainVideo, _rawSubtitle, _rawExcerpt, useVideo, mainImage, members, authors, publishedAt, hideOverlay, hideSubtitle}) => {
  return (
		<Container 
			className={'banner'}>
      <Row>
				<Container>
					<Row>
						<div className={'banner-child'} style={{
							...(excerptAlignment(position))
						}}>
							{_rawExcerpt && (<BlockContent blocks={_rawExcerpt || []} />)}
						</div>
					</Row>
				</Container>
				{
					(mainImage || mainVideo) && !hideOverlay ?
					<div className={'overlay'} /> :
					null
				}
				<div className={`banner-child__brand ${ _rawSubtitle && (mainImage || mainVideo) ? 'background-content' : 'empty'}`}>
				{
					pageBranding && pageBranding.asset && (
						<div className={'branding'}>
							<img
								src = {
									imageUrlFor(buildImageObj(pageBranding))
									.width(300)
									.url()}
							/>
						</div>
					)
				}
					{ (_rawSubtitle  && !hideSubtitle && (!mainImage && !mainVideo) ) && (<h1 style={{textAlign: 'center', padding: 60, height: '100vh', width: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center', backgroundColor: '#fff', color: '#5e4ca5'}}><BlockContent blocks={_rawSubtitle || []} /></h1>) }
					{ _rawSubtitle && !hideSubtitle && (<BlockContent blocks={_rawSubtitle || []} />) }
					{
						authors && (
							<div className="author">
								<div style={{
									display: 'flex',
									flexFlow: 'column',
									justifyContent: 'center',
									alignItems: 'center'
								}}>
									{
										authors[0].person.image &&
										(<img src={imageUrlFor(buildImageObj(authors[0].person.image)).url()} alt={authors[0].person.name} className="avatar img-raised" />)
									}
									<h4 className="category">
										{differenceInDays(new Date(publishedAt), new Date()) > 3
										? distanceInWords(new Date(publishedAt), new Date())
										: format(new Date(publishedAt), 'MMMM Do YYYY')}
									</h4>
									{
										authors[0].person.name && (
										<h4 className={'description'}>{authors[0].person.name}</h4>
										)
									}
								</div>
							</div>
						)
					}
				</div>
				{
					useVideo && mainVideo ?
					<VideoEmbed { ...mainVideo}/>
					: null
				}
				{
					!useVideo && mainImage ?
					<div className={'background-image'} style={{
						backgroundImage: `url(${imageUrlFor(buildImageObj(mainImage)).width(1600).url()})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
					}} />
					: null
				}
      </Row>

    </Container>
   )
}

export default VideoBanner;
