import React from 'react'
import NavBar from '../common/navbar/navbar'
import Footer from '../common/footer/footer'
import styles from './layout.module.css'

const Layout = ({ children, companyInfo, onHideNav, onShowNav, showNav, siteTitle, siteLogo, siteLogoAlt, transparent }) => (
  <>
    <NavBar transparent={transparent} siteTitle={siteTitle} companyInfo={companyInfo} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} siteLogo={siteLogo} />
    <div className={"section section-basic"}>{children}</div>
    <Footer companyInfo={companyInfo} siteLogo={siteLogoAlt}/>
  </>
)

export default Layout
